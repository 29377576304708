var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { loading: _vm.loading.detail, title: _vm.title } },
    [
      _c(
        "a-form-model",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _vm.formData.documentNo
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_document_number") } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ant-form-text",
                              attrs: { "data-testid": "srf-doc-number" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.formData.documentNo) + " "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_request_date"),
                        prop: "requestDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { disabled: _vm.hasReference },
                        model: {
                          value: _vm.formData.requestDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "requestDate", $$v)
                          },
                          expression: "formData.requestDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer"),
                        prop: "customer"
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.hasReference
                            ? {
                                key: "extra",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("common.reference-text", {
                                            text: _vm.dtReference.customer
                                          })
                                        ) +
                                        " "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("SelectCustomer", {
                        attrs: { "label-in-value": "" },
                        on: { "update:meta": _vm.handleCustomerChange },
                        model: {
                          value: _vm.formData.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "customer", $$v)
                          },
                          expression: "formData.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_address"),
                        prop: "address"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: { "data-testid": "srf-doc-number" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.formData.addressRef || "-") + " "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_branch"),
                        prop: "branch"
                      }
                    },
                    [
                      _c("SelectBranch", {
                        attrs: {
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_branch")
                          }),
                          "label-in-value": "",
                          disabled: _vm.isUpdate
                        },
                        model: {
                          value: _vm.formData.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "branch", $$v)
                          },
                          expression: "formData.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_mechanic"),
                        prop: "mechanic"
                      }
                    },
                    [
                      _c("SelectMechanic", {
                        attrs: {
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_mechanic")
                          }),
                          "label-in-value": ""
                        },
                        model: {
                          value: _vm.formData.mechanic,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "mechanic", $$v)
                          },
                          expression: "formData.mechanic"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_description"),
                        prop: "description"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.formData.description }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_description")
                          })
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.formData.status
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_status")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ant-form-text",
                              attrs: { "data-testid": "srf-status" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.toTitlecase(_vm.formData.status)) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "mt-4", attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_add_more_unit")))]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleDeleteRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.dtReference.units.length &&
              _vm.dtReference.units[_vm.currentUnitPage - 1]
                ? _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-descriptions",
                        {
                          attrs: {
                            title: _vm.$t("common.reference-text", {
                              text: _vm.$t("lbl_unit")
                            }),
                            bordered: "",
                            layout: "vertical",
                            size: "small"
                          }
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_unit_code") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dtReference.units[
                                      _vm.currentUnitPage - 1
                                    ].unitCode
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_brand") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dtReference.units[
                                      _vm.currentUnitPage - 1
                                    ].brand
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_type") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dtReference.units[
                                      _vm.currentUnitPage - 1
                                    ].type
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      dataSource: _vm.formData.units,
                      columns: _vm.columns,
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "row-selection": {
                        selectedRowKeys: _vm.selectedRowIds,
                        onChange: _vm.handleSelectedRowIdChange
                      },
                      pagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        },
                        pageSize: 1,
                        current: _vm.currentUnitPage,
                        onChange: function(page) {
                          _vm.currentUnitPage = page
                        }
                      },
                      expandedRowKeys: _vm.allRowIds
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "unitCode",
                        fn: function(_, record) {
                          return [
                            _c("a-select", {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                "label-in-value": "",
                                "filter-option": false,
                                loading:
                                  _vm.loading.fetchUnitCode ||
                                  record.unitCodeLoading,
                                "dropdown-match-select-width": false,
                                options: record.useLineOptions
                                  ? record.unitCodeOptions
                                  : _vm.unitCodeOptions
                              },
                              on: {
                                search: function($event) {
                                  return _vm.handleAssetSearch($event, record)
                                },
                                change: function($event) {
                                  return _vm.handleAssetSelected($event, record)
                                }
                              },
                              model: {
                                value: record.unitCode,
                                callback: function($$v) {
                                  _vm.$set(record, "unitCode", $$v)
                                },
                                expression: "record.unitCode"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "expandedRowRender",
                        fn: function(record) {
                          return [
                            _c("a-table", {
                              attrs: {
                                size: "small",
                                "data-source": record.parts,
                                columns: _vm.innerColumns,
                                pagination: false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "action",
                                    fn: function(_, part) {
                                      return [
                                        _c("a-button", {
                                          attrs: {
                                            type: "danger",
                                            icon: "delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDeletePart(
                                                record,
                                                part
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "image",
                                    fn: function(_, part) {
                                      return [
                                        part.imageUrl
                                          ? [
                                              _c("a-icon", {
                                                attrs: { type: "file-image" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showImage(part)
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                " " + _vm._s(part.partRef) + " "
                                              )
                                            ]
                                          : _c("p", [_vm._v("-")])
                                      ]
                                    }
                                  },
                                  {
                                    key: "partRef",
                                    fn: function(_, part) {
                                      return [
                                        _c("a-input", {
                                          attrs: { disabled: part.disabled },
                                          model: {
                                            value: part.partRef,
                                            callback: function($$v) {
                                              _vm.$set(part, "partRef", $$v)
                                            },
                                            expression: "part.partRef"
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "uomRef",
                                    fn: function(_, part) {
                                      return [
                                        _c("a-input", {
                                          attrs: { disabled: part.disabled },
                                          model: {
                                            value: part.uomRef,
                                            callback: function($$v) {
                                              _vm.$set(part, "uomRef", $$v)
                                            },
                                            expression: "part.uomRef"
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "quantity",
                                    fn: function(_, part) {
                                      return [
                                        _c("a-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            min: 0,
                                            parser: _vm.reverseFormatNumber,
                                            formatter: _vm.formatterNumber,
                                            precision:
                                              _vm.storeBaseDecimalPlace,
                                            disabled: part.disabled
                                          },
                                          model: {
                                            value: part.quantity,
                                            callback: function($$v) {
                                              _vm.$set(part, "quantity", $$v)
                                            },
                                            expression: "part.quantity"
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "footer",
                                    fn: function() {
                                      return [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleAddPart(record)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("lbl_add_row")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "mt-3", attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.createSparepartRequest
                          },
                          on: { click: _vm.handleBack }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                      ),
                      !_vm.hasReference &&
                      _vm.$can("create", "spare-part-request-desktop")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                "data-testid": "srf-submit",
                                loading: _vm.loading.createSparepartRequest
                              },
                              on: { click: _vm.handleSubmit }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_submit_and_approve")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isEdit &&
                      _vm.hasReference &&
                      _vm.$can("update", "spare-part-request-desktop")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                loading: _vm.loading.reject,
                                "data-testid": "srf-reject"
                              },
                              on: { click: _vm.confirmReject }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_reject")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isEdit &&
                      _vm.hasReference &&
                      _vm.$can("create", "spare-part-request-desktop")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.createSparepartRequest,
                                "data-testid": "srf-approve"
                              },
                              on: { click: _vm.handleApprove }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("vue-light-box", {
        attrs: {
          visible: _vm.imageViewData.visible,
          imgs: _vm.imageViewData.url
        },
        on: { hide: _vm.closeImage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }